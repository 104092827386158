'use strict';
var $ = window.$;

let mediaXxs = 320;
let mediaXs = 480;
let mediaSm = 768;
let mediaMd = 1024;
let mediaLg = 1200;
let mediaXl = 1440;
let mediaXxl = 1600;
let mediaXxxl = 1920;

$(document).ready(function () {

  $.fancybox.defaults.touch = false;
  $.fancybox.defaults.toolbar = false;
  $.fancybox.defaults.infobar = false;
  $.fancybox.defaults.arrows = false;
	$.fancybox.defaults.autoFocus = false;

	$('[data-fancybox="images"]').fancybox({
		toolbar: true,
	});
	
		// �������� ���������� ����
		$('.js-open-menu').on('click', function(e){
			$(this).toggleClass('is-open');
			$('#mobile-menu').toggleClass('is-open');
		});
		
		// �������� ���������� ����
		$('.js-close-menu').on('click', function(e){
			$('#hamburger').removeClass('is-open');
			$('#mobile-menu').removeClass('is-open');
		});

		// �������� ����� ������
		$('.js-open-form').on('click', function(e){
			$(this).toggleClass('is-open');
			$('.head-dashboard__search').addClass('is-open');
		});
		
		// �������� ����� ������
		$('.js-close-form').on('click', function(e){
			$('.head-dashboard__search').removeClass('is-open');
		});


	// ����������� 

	$(document).on('click', '[js-copy-text]:not(.active)', function () {
		let $code = $(this).find(".article-table__code");
    let $button = $(this).find(".article-table__copy");
    let aux = $('<input>');
		$('body').append( aux );
    aux.val( $code.text() ).select();
    document.execCommand("copy");
    aux.remove();
    let text = $button.text();
    $button.text('�����������');
    $button.addClass('active');
    setTimeout(function(){
      $button.text(text);
      $button.removeClass('active');
    }, 1000);
  });

		// �������
		
  $('[product-slider]').each(function(){
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
		let $dots = $block.find('[class*="-dots"]');
		let $prev = $block.find('[class*="_prev"]');
		let $next = $block.find('[class*="_next"]');

		$init.slick({
			slidesToShow: 4,
			arrows: true,
			dots: false,
			// variableWidth: true,
			prevArrow: $prev,
			nextArrow: $next,
			responsive: [
				{ breakpoint: mediaXs,
					settings: {
						slidesToShow: 1,
					},
				},
				{ breakpoint: mediaLg,
					settings: {
						slidesToShow: 2,
					},
				},
				// { breakpoint: mediaXxl,
				// 	settings: {
				// 		slidesToShow: 3,
				// 	},
				// },
			]
		});
	});

	// ������� � �������� 

	$('[article-slider]').each(function() {
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
		let $nav = $block.find('[class*="__nav"]');
		let $prev = $block.find('[class*="_prev"]');
		let $next = $block.find('[class*="_next"]');

		$init.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots: false,
			fade: true,
			// variableWidth: true,
			prevArrow: $prev,
			nextArrow: $next,
			// asNavFor: $nav,
		});

		$nav.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: $init,
			dots: false,
			arrows: false,
			infinite: false,
			// centerMode: true,
			focusOnSelect: true,
			// variableWidth: false,
			vertical: true,
			verticalSwiping: true,
			responsive: [
				{ breakpoint: mediaSm,
					settings: {
						slidesToShow: 5,
						// infinite: true,
						slidesToScroll: 1,
						slidesToScroll: 1,
						// variableWidth: true,
						vertical: false,
						verticalSwiping: false,
						// centerMode: true,
						focusOnSelect: true,
					},
				},
					{ breakpoint: mediaXs,
						settings: {
							slidesToShow: 4,
							vertical: false,
							verticalSwiping: false,
							// centerMode: true,
							focusOnSelect: true,
						},
					},
			],
			// mobileFirst: true,
		});

	});

	// ������� � ��������� ����

	$('[modal-slider]').each(function() {
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
		let $nav = $block.find('[class*="__nav"]');
		let $prev = $block.find('[class*="_prev"]');
		let $next = $block.find('[class*="_next"]');


		$init.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots: false,
			fade: true,
			// variableWidth: true,
			prevArrow: $prev,
			nextArrow: $next,
			asNavFor: $nav,
		});

		$nav.slick({
			// slidesToShow: 4,
			slidesToScroll: 1,
			asNavFor: $init,
			dots: false,
			arrows: false,
			infinite: true,
			variableWidth: true,
			// centerMode: true,
			focusOnSelect: true,
			// vertical: false,
		});
	})

	// input

	$(document).on('change', '.input', function(){
    animateInput(this)
  })

  $.each( $('.input'), function( index, element ) {
    animateInput(element)
  });

	$(document).on('change', '.textarea', function(){
    animateInput(this)
  })

  $.each( $('.textarea'), function( index, element ) {
    animateInput(element)
  });


  function animateInput(input){
    if ($(input).val().length > 0) {
      $(input).addClass('is-full');
    } else {
      $(input).removeClass('is-full')
    }
	}
	
	// �������� �������������

	$('.article-description__button').on('click', function(e) {
			$(this).toggleClass('is-open');
			$('.article-description__container_hide').toggleClass('is-open');
			$('.article-description__container_visible').toggleClass('is-open');

			if ($(this).hasClass('is-open')) {
				$(this).text('������');
			} else {
				$(this).text('��� ��������������');
			}
	})

		// �������� ��������

		$('.article-delivery__button').on('click', function(e) {
				$(this).toggleClass('is-open');
				$('.article-delivery__item_hidden').toggleClass('is-open');
				$('.article-delivery__item_visible').toggleClass('is-open');
	
				if ($(this).hasClass('is-open')) {
					$(this).text('������');
				} else {
					$(this).text('���������');
				}
		})

		// ������ ��������

		function selectWidth() {
			let $block = $('.select');

			$block.find('.select__button-inner').each(function(i, el) {
				let selectItemWidth = $(el).width();
				$(el).css('width', selectItemWidth + 'px');
			})
		}

		  // ��������� ���������
			window.initNiceScroll = function(selector) {
				var $container = selector instanceof jQuery ? selector:$(selector);
				$container.niceScroll({
					cursorwidth: 4,
					cursorborderradius: '3px',
					cursorcolor: '#9E9E9E',
					cursorborder: 'none',
					background: "#FFF"
				});
			};
			window.initNiceScroll(".has-scrollbar");
	
	window.onload = function(){
		// sliderAlign('[banner-slider]');
		// sliderAlign('[info-slider]');
		// sliderAlign('[reviews-slider]');
		// sliderAlign('[before-after-slider]');
		// sliderAlign('[one-img-slider]');
		// sliderAlign('[variety-slider]');
		// sliderAlign('[article-slider]');
		// sliderAlign('[modal-slider]');
		selectWidth();
	}
	
	$(window).on('resize', function(){
		// sliderAlign('[banner-slider]');
		// sliderAlign('[info-slider]');
		// sliderAlign('[reviews-slider]');
		// sliderAlign('[before-after-slider]');
		// sliderAlign('[one-img-slider]');
		// sliderAlign('[variety-slider]');
		// sliderAlign('[article-slider]');
		// sliderAlign('[modal-slider]');
	});
	
	function sliderAlign(slider) {
		$(slider).each(function(index, element){
			var slickTrack = $(element).find('.slick-track');
      slickTrack.css('height', 'auto');
      var sliderHeight = slickTrack.height();
      slickTrack.css('height', sliderHeight + 'px');
    });
	}

});